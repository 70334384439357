import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
// import LoginAdmin from './views/LoginAdmin.vue';
import Register from './views/Register.vue';
import ResetPassword from './views/ResetPassword.vue';
// import ResetPasswordAdmin from './views/ResetPasswordAdmin.vue';
import RecoveryPassword from './views/RecoveryPassword.vue';
// import RecoveryPasswordAdmin from './views/RecoveryPasswordAdmin.vue';
import ChangePassword from './views/ChangePassword.vue';
import Home from './views/Home.vue';
import Offer from './views/Offer.vue';
// import NotFound from './views/NotFound.vue';
import store from './store';
import i18n from './i18n';


Vue.use(Router);

const isAdminPanel = window.location.pathname.indexOf('/admin') > -1;

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        requiresAuth: true
      },
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {hideNavigation: true}
    },
    {
      path: '/sso',
      name: 'SSO',
      meta: {
        requiresAuth: false,
        hideNavigation: true
      },
      component: () => import(/* webpackChunkName: 'data-protection-notes' */ './views/Sso.vue')
    },
    {
      path: '/offer/:token',
      name: 'Offer',
      component: Offer,
      meta: {hideNavigation: true},
    },
    {
      path: '/admin/login',
      name: 'admin-login',
      // component: LoginAdmin,
      component: () => import(/* webpackChunkName: 'login-admin' */ './views/LoginAdmin.vue'),
      meta: {hideNavigation: true}
    },
    {
      path: '/login/:token',
      name: 'change-password',
      component: ChangePassword,
      meta: {hideNavigation: true},
      beforeEnter: async (to, from, next) => {
        await store.commit('SET_SESSION_DATA', {
          ...store.state.session,
          token: to.params.token
        });

        next();
      }
    },
    {
      path: '/admin/reset-password',
      name: 'admin-reset-password',
      // component: ResetPasswordAdmin,
      component: () => import(/* webpackChunkName: 'reset-password-admin' */ './views/ResetPasswordAdmin.vue'),
      meta: {hideNavigation: true}
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {hideNavigation: true}
    },
    {
      path: '/recovery-password/:token',
      name: 'recovery-password',
      component: RecoveryPassword,
      meta: {hideNavigation: true},
      beforeEnter: async (to, from, next) => {
        await store.commit('SET_SESSION_DATA', {
          ...store.state.session,
          token: to.params.token
        });

        next();
      }
    },
    {
      path: '/admin/recovery-password/:token',
      name: 'admin-recovery-password',
      // component: RecoveryPasswordAdmin,
      component: () => import(/* webpackChunkName: 'recovery-password-admin' */ './views/RecoveryPasswordAdmin.vue'),
      meta: {hideNavigation: true},
      beforeEnter: async (to, from, next) => {
        await store.commit('SET_SESSION_DATA', {
          ...store.state.session,
          token: to.params.token
        });

        next();
      }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {hideNavigation: true}
    },
    {
      path: '/users',
      name: 'users',
      meta: {
        requiresAuth: true,
        roles: ['policyHolder', 'manager']
      },
      // route level code-splitting
      // this generates a separate chunk (users.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: 'users' */ './views/Users.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_USERS');
        await store.dispatch('ADD_EMPLOYER');
        next();
      }
    },
    {
      path: '/contract',
      name: 'contract',
      meta: {
        requiresAuth: true,
        roles: ['policyHolder']
      },
      component: () =>
        import(/* webpackChunkName: 'contract' */ './views/Contract'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('getContract');
        next();
      }
    },
    {
      path: '/phishing/your-company',
      name: 'phishing',
      meta: {
        requiresAuth: true,
        roles: ['policyHolder', 'manager', 'trial']
      },
      component: () => import(/* webpackChunkName: 'phishing' */ './views/phishing/YourCompany.vue')
    },
    {
      path: '/phishing/campaigns',
      name: 'phishingCampaigns',
      meta: {
        requiresAuth: true,
        roles: ['policyHolder', 'manager', 'trial']
      },
      component: () =>
        import(/* webpackChunkName: 'phishingCampaigns' */ './views/phishing/Campaigns.vue')
    },
    {
      path: '/admin/policyholders',
      name: 'policyholders',
      meta: {
        requiresAuth: true,
        roles: ['admin']
      },
      component: () =>
        import(/* webpackChunkName: 'policyholders' */ './views/Policyholders.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_EMPLOYERS');
        next();
      }
    },
    {path: '/admin', redirect: '/admin/policyholders'},
    {
      path: '/admin/admins',
      name: 'admins',
      meta: {
        requiresAuth: true,
        roles: ['admin']
      },
      component: () =>
        import(/* webpackChunkName: 'admins' */ './views/Admins.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_ADMINS');
        next();
      }
    },
    {
      path: '/admin/policyholders/:id',
      name: 'policyholder-details',
      meta: {
        requiresAuth: true,
        roles: ['admin']
      },
      component: () =>
        import(/* webpackChunkName: 'policyholder-details' */ './views/PolicyholderDetails.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_USER_DETAILS', to.params.id);
        next();
      }
    },
    {
      path: '/course-settings',
      name: 'course-settings',
      meta: {
        requiresAuth: true,
        roles: ['policyHolder', 'manager']
      },
      component: () => import(/* webpackChunkName: 'course-settings' */ './views/CourseSettings.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_USERS'),
          store.dispatch('GET_COURSES'),
          store.dispatch('GET_COMPANY_PROGRESS')
        ]);
        next();
      }
    },
    {
      path: '/proofOfInsurance',
      name: 'proofOfInsurance',
      meta: {
        requiresAuth: true,
        roles: ['policyHolder', 'manager'],
      },
      component: () => import(/* webpackChunkName: 'proofOfInsurance' */ './views/ProofOfInsurance.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_USERS'),
          store.dispatch('GET_COURSES'),
          store.dispatch('GET_COMPANY_PROGRESS'),
          store.dispatch('GET_COMPANY_STATISTIC'),
        ]);
        next();
      },
    },
    {
      path: '/personalCertificate',
      name: 'personalCertificate',
      meta: {
        requiresAuth: true,
        
      },
      component: () => import(/* webpackChunkName: 'proofOfInsurance' */ './views/PersonalCertificate.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_USERS'),
          store.dispatch('GET_COURSES'),
          store.dispatch('GET_COURSE_PROGRESSES')
        ]);
        next();
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: {
        requiresAuth: true,
        roles: ['policyHolder', 'manager']
      },
      component: () => import(/* webpackChunkName: 'dashboard' */ './views/Dashboard.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_USERS'),
          store.dispatch('GET_COURSES'),
          store.dispatch('GET_COMPANY_PROGRESS'),
          store.dispatch('GET_COMPANY_STATISTIC')
        ]);
        await store.dispatch('ADD_EMPLOYER');
        next();
      }
    },
    {
      path: '/trainings',
      name: 'trainings',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: 'my-trainings' */ './views/MyTrainings.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_COURSES'),
          store.dispatch('GET_COURSE_PROGRESSES')
        ]);
        next();
      }
    },
    {
      path: '/trainings/:slug',
      name: 'training',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: 'my-training' */ './views/MyTraining.vue'),
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_COURSE_DATA', to.params.slug),
          store.dispatch('GET_COURSE_PROGRESS', to.params.slug)
        ]);
        next();
      },
      children: [
        {
          path: 'quiz/:step',
          name: 'training-quiz',
          meta: {
            quiz: true
          },
          component: () => import(/* webpackChunkName: 'my-training-quiz' */ './views/MyTrainingQuiz.vue')
        },
        {
          path: ':slide?/:step?',
          name: 'training-module',
          meta: {
            module: true
          },
          component: () => import(/* webpackChunkName: 'my-training-module' */ './views/MyTrainingModule.vue')
        }
      ]
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: 'account-settings' */ './views/AccountSettings.vue'),
      beforeEnter: async (to, from, next) => {
        await await store.dispatch('INIT_USER_DATA', true);
        next();
      }
    },
    {
      path: '/impressum',
      name: 'impressum',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: 'impressum' */ './views/Impressum.vue')
    },
    {
      path: '/datenschutzhinweise',
      name: 'datenschutzhinweise',
      meta: {
        requiresAuth: false,
        hideNavigation: true
      },
      component: () => import(/* webpackChunkName: 'datenschutzhinweise' */ './views/Datenschutzhinweise.vue')
    },
    {
      path: '/data-protection-notes',
      name: 'data-protection-notes',
      meta: {
        requiresAuth: false,
        hideNavigation: true
      },
      component: () => import(/* webpackChunkName: 'data-protection-notes' */ './views/DataProtectionNotes.vue')
    },
    {
      path: '/vodafone',
      name: 'vodafone',
      meta: {
        requiresAuth: false,
        hideNavigation: true
      },
      component: () => import(/* webpackChunkName: 'data-protection-notes' */ './views/Vodafone.vue')
    },
    {
      path: '/web-security-check',
      name: 'web-security-check',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: 'data-protection-notes' */ './views/WebSecurityCheck.vue')
    }
  ]
});
router.beforeEach(async (to, from, next) => {

  // in case there is a user logged in and at the same time they try to access the affiliate link
  // first sign them out, then redirect to the original link.
  const url = window.location.href

  if (url.includes('ref=') && url.includes('/versicherung') && store.getters.logged) {
    const logoutType = store.getters.isAdmin ? 'LOG_OUT_ADMIN' : 'LOG_OUT';
    const { success, message = 'Something went wrong' } = await store.dispatch(logoutType);
    
    if (success) {
      Vue.toasted.show(i18n.t('logout.logout_success'), {type: 'success'});
      next(url);
    } else {
      Vue.toasted.show(message, {type: 'error'});
      return next('/');
    }
  }
  // RateUs Modal after login
  // CYB-4121 hiding RateUs modal, but keep code for later
  // if (from.path === '/login') {
  //   if (store.state.account && store.state.account.user && store.state.account.user.createdAt) {
  //     const lead = store.state.account.user.lead
  //     const exceptionLead = 'alte-leipziger'
  //     const registered = new Date(store.state.account.user.createdAt)
  //     const today = new Date()
  //     const diffTime = Math.abs(registered - today);
  //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //     if (lead !== exceptionLead && diffDays >= 30 && store.state.account.user.showSurveyPopUp) {
  //       store.dispatch('OPEN_MODAL', {header: '', componentName: 'RateUs', footer: '', modalStyle: 'big', closeable: true});
  //     }
  //   }
  // }
  
  // /RateUs Modal after login


  if (to.path === '/logout' && store.getters.logged) {
    const logoutType = store.getters.isAdmin ? 'LOG_OUT_ADMIN' : 'LOG_OUT';
    
    const lead  = store.getters.lead ;
    
    const {success, message = 'Something went wrong'} = await store.dispatch(logoutType);
    
    if (success) {
      Vue.toasted.show(i18n.t('logout.logout_success'), {type: 'success'});
    } else {
      Vue.toasted.show(message, {type: 'error'});
    }

    if (logoutType === 'LOG_OUT_ADMIN') {
      return next('/admin/login');
    } else {
      if( lead && lead['alte-leipziger'] ) {
        return next('/login?lead=alte-leipziger');
      } else {
        return next('/login');
      }
    }
  }

  if (to.path.indexOf('/recovery-password') > -1) {
    return next();
  }

  if (store.getters.logged && !store.getters.isAdmin) {
    await store.dispatch('INIT_USER_DATA');
  }

  if (to.path === '/' && store.getters.isAdmin) return next('/admin/policyholders');
  if (to.path === '/' && (store.getters.isEmployer || store.getters.isManager)) return next('/users');
  if (to.path === '/' && store.getters.isEmployee) return next('/trainings');
  if (to.path === '/' && store.getters.isTrial) return next('/phishing/your-company');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const loc = new URL(window.location.href)

    if (!store.getters.logged) {
      if (loc.host === 'alte-leipziger.cyberdirekt.de' || loc.host === 'www.alte-leipziger.cyberdirekt.de') {
        return next({
          path: '/register',
          query: {lead: 'alte-leipziger' }
        });
      } else {
        return next({
          path: isAdminPanel ? '/admin/login' : '/login',
          query: {next: to.fullPath}
        });
      }
    } else {
      if (to.matched.some(record => record.meta.roles && record.meta.roles.indexOf(store.getters.role) === -1)) {
        return next('/');
      }
    }
  }
  store.commit('SET_ROUTE_FROM', from);
  // check whether user was created before today
  const timeStamp = new Date('2023-08-03'); /// the date when we intorduced this rule, older users we ignore.
  const userCreated = new Date(store.state.account.user.createdAt);
  let showWelcome = store.state.account.user.createdAt ? timeStamp.getTime() < userCreated.getTime() : false;

  // check whether AVV agreed
  if (to.path.indexOf('/login') === -1 && to.path.indexOf('/logout') === -1 && store.getters.logged && to.name !== 'Offer') {
    if (store.getters.role === 'policyHolder' && (!store.state.account.user.orderProcessingChecked || store.state.account.user.orderProcessingChecked === undefined)) {
      store.dispatch('OPEN_MODAL', { header: '', componentName: 'WelcomeModal', footer: '', modalStyle: 'md', closeable: false });
      // update the user with welcomeModalShownFlag
      store.dispatch('WELCOME_MODAL_SHOWN', { welcomeModalShown: true });
    } else if ( from.path === '/login' && !store.state.account.user.welcomeModalShown && showWelcome) {
      // show welcome modal without AVV
      store.dispatch('OPEN_MODAL', { header: '', componentName: 'WelcomeModal', footer: '', modalStyle: 'md', closeable: true, isWithoutAvv: true });
      // update the user with welcomeModalShownFlag
      store.dispatch('WELCOME_MODAL_SHOWN', { welcomeModalShown: true });
    }
  }
  
  next();
});

export default router;